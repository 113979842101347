<template>
    <div class="homeDiv">
        <!-- <div class="buttons">
            <div>
                <smallbutton toplabel="Go to TPR" buttonclass="buttonprimary widebutton" @click="gotpr()" />
            </div>
        </div> -->
        <div class="leftjustified margin10">
            <linkbutton :toplabel="lookuptogglelabel" @click="togglelookup()"/>
        </div>

        <div class="filter horizFlex wrap " v-on:keyup.enter="filtertime" v-if="showLookup">
            <p class="filterlabel">Lookup</p>
            <input class="filterfield" type="date" v-model="filter.StartDateTime"/>
            <p class="filterlabel">to</p>
            <input class="filterfield" type="date" v-model="filter.EndDateTime"/>
            
            <select name="" id="" class='filterselect' v-model="filter.LocationID" required @change="selectLocation()">
                <option v-for="loc in locationList" :key="loc.id" :value="loc.id" >
                    {{loc.name}}
                </option>
            </select>
            <select name="" id="" class='filterselect' v-model="filter.StudentID" required >
                <option v-for="s in studentList" :key="s.id" :value="s.id" >
                    {{s.name}}
                </option>
            </select>
      <!--       <select name="" id="" class='filterselect' v-model="filter.InstructorID" required >
                <option v-for="i in instructorList" :key="i.id" :value="i.id" >
                    {{i.name}}
                </option>
            </select> -->
            <select name="" id="" class='filterselect' v-model="filter.studentGroupId" required v-if="showStudentGroup">
                <option v-for="c in studentGroupList" :key="c.ID" :value="c.ID" >
                    {{c.Name}}
                </option>
            </select>
  
            <select name="" id="" class='filterselect' v-model="filter.procedureSequenceId" required >
                <option v-for="c in procedureList" :key="c.id" :value="c.id" >
                    {{c.name}}
                </option>
            </select>
            <div v-if="showStartDate" class="horizFlex">
                <p >Start Date</p>
                <input type="date" v-model="filter.studentStartDate" class='filterselect' /> 
            </div>
            <div class="horizFlex">
                <input class="filterfield" type="checkbox" v-model="filter.IncludeOnlyActiveStatus"/>
                <p class="filterlabel">include removed</p>
            </div>
            <smallbutton toplabel="Go" @click="filtertime" buttonclass="buttonprimary narrowbutton"/>
            <smallbutton toplabel="Reset" buttonclass="buttonprimary narrowbutton" @click="resetfilter"/>
            
            
            
        </div>

        <div class="margin10" v-if="pleaseWait">
                <label class="pleasewait">Please Wait...</label>
        </div>
     
        <div class="horizFlexStretch">
            <p class="bold">{{ totalsString }}</p>
            <div class="horizFlexStretch" v-if="!showApprove">
                <addtimeitem :showButton="paramTrue" buttonclass="buttonprimary actionbutton" @refresh="filtertime"/>
                <smallbutton toplabel="Approve Time" buttonclass="buttonprimary actionbutton" @click="showApprove=!showApprove"/>
            </div>
            <div class="horizFlexStretch" v-if="showApprove">
                <smallbutton toplabel="Save Approvals" buttonclass="buttonprimary actionbutton" @click="saveApprovals"/>
                <smallbutton toplabel="Cancel" buttonclass="buttonprimary actionbutton" @click="showApprove=false"/>
            </div>
        </div>
        <div >
            <div class="timeheader" >
                <linkbutton class="recordlabel bold" @click="setsort('name')" toplabel="Student"/>
                <linkbutton class="recordlabel bold" @click="setsort('date')" toplabel="Date"/>
                <linkbutton class="recordlabel bold" @click="setsort('timein')" toplabel="Time In"/>
                <linkbutton class="recordlabel bold" @click="setsort('timeout')" toplabel="Time Out"/>
                <linkbutton class="recordlabel bold" @click="setsort('course')" toplabel="Course"/>
                <linkbutton class="recordlabel bold" @click="setsort('hourscourse')" toplabel="Course Hours"/>
                <linkbutton class="recordlabel bold" @click="setsort('hoursbtw')" toplabel="BTW Hours"/>
                <p class="recordlabel bold recordheader">Notes</p>    
                <div>
                    <p class="recordlabel bold recordheader" v-if="showApprove">Approve</p>   
                    <!-- <linkbutton v-if="showApprove" class="recordlabel bold" @click="sortBy='notes'" toplabel="Approve"/> -->
                  
                </div>
            </div>
            <div class="vertScroll">
                <div v-for="g in sortedTimeList" :key="g.ID" :value="g.ID" :class="recordClass(g)">

                    <p class="recordlabel">{{g.StudentName}}</p>
                    <p class="recordlabel">{{formatDate(g.StartDateTime) }}</p>
                    <p class="recordlabel">{{formatTime_early(g.enteredCourseStartTime,g.enteredCourseStartTime2) }}</p>
                    <p class="recordlabel">{{formatTime_late(g.enteredCourseEndTime,g.enteredCourseEndTime2) }}</p>
                    <p class="recordlabel">{{formatProductName(g)}}</p>
                    <p class="recordlabel">{{formatHours(g.totalHours_Course,g.totalHours_Course2)}}</p>
                    <p class="recordlabel">{{formatHours(g.totalHours_BTW,g.totalHours_BTW2)}}</p>
                    <p class="recordlabel">{{g.timeNotes_Course}}</p>

                    <div class="horizFlex" v-if="!showApprove">
                        <!-- <linkbutton toplabel="Update" class="margin10" @click="update(g)" /> -->
                        <updatetimeitem :timeitem="g" :courseList="procedureList" :studentlist="studentList" v-if="okToUpdate(g)" class="margin10" @refresh="filtertime"/>
                        <div class="removeme" v-if="g.statusText!='Removed' && g.statusText!='Approved'">
                            <confirm :showLink="paramTrue" :body="getRemoveString(g.StudentName)" title="Remove"  v-if="okToRemove(g)"
                                @yes="remove(g)" />
                        </div>
                        <p class="recordlabel" v-if="g.tprManuallyUploaded">**MANUALLY UPLOADED**</p>
                    </div>
                    <div v-if="showApprove">
                        <input type="checkbox" @change="setApprove(g.ID)" class="approveCheck" v-if="okToApprove(g)"/>
                    </div>
                </div>
            </div>
            
        </div>

    </div>
</template>
  
  <script>
  import {mapFields} from 'vuex-map-fields'
  import datamethods from '../../services/datamethods.js'
  import format from '../../format.js'
  import smallbutton from '../controls/button.vue'
  import linkbutton from '../controls/linkbutton'
  import confirm from '../actions/confirm.vue'
  import securityhelper from '../../services/securityhelper.js'
  import updatetimeitem from './updatetimeitem.vue'
  import addtimeitem from './addtimeitem.vue'
  export default {
      name:'timeview',
      mixins:[datamethods, format,securityhelper],
      components:{
          smallbutton,
          linkbutton,
          confirm,
          updatetimeitem,
          addtimeitem

      },
      data(){
          return{
            
            paramTrue:true,
            paramFalse:false,   
            
            showLookup:true,
            sortBy:'default',
            showApprove:false,
            sortaccending:true
          }
      },
      computed:{
        showStudentGroup(){
            if(window.SHOW_STUDENTGROUP==undefined){return false}
            else{return window.SHOW_STUDENTGROUP}
        },
        
        showStartDate(){
            if(window.SHOW_STARTDATE==undefined){return false}
            else{return window.SHOW_STARTDATE}
        },
        showProductFilter(){
               
               if(window.SHOW_PRODUCT_FILTER==undefined){return true}
               else{return window.SHOW_PRODUCT_FILTER}
           },
        showRemove(){
            if(window.SHOW_REMOVE_TIME==undefined){return false}
            else{return window.SHOW_REMOVE_TIME}
        },
        sortedTimeList(){
            if(this.sortBy=='default'){return this.timeList}

            var sort1 = this.sortaccending ? -1:1
            var sort2 = this.sortaccending ? 1:-1

            
            switch (this.sortBy) {
                case 'name':
                    function compare(a,b){
                        if (a.StudentName < b.StudentName)
                            return sort1
                        if(a.StudentName > b.StudentName)
                            return sort2
                        return 0
                    }
                    return this.timeList.sort(compare)
                    break;
                case 'date':
                    function compareb(a,b){
                        if (a.StartDateTime < b.StartDateTime)
                            return sort1
                        if(a.StartDateTime > b.StartDateTime)
                            return sort2
                        return 0
                    }
                    return this.timeList.sort(compareb)
                    break;
                case 'timein':
                    function comparec(a,b){
                        if (a.enteredCourseStartTime < b.enteredCourseStartTime)
                            return sort1
                        if(a.enteredCourseStartTime > b.enteredCourseStartTime)
                            return sort2
                        return 0
                    }
                    return this.timeList.sort(comparec)
                    break;    
                case 'timeout':
                    function compared(a,b){
                        if (a.enteredCourseEndTime < b.enteredCourseEndTime)
                            return sort1
                        if(a.enteredCourseEndTime > b.enteredCourseEndTime)
                            return sort2
                        return 0
                    }
                    return this.timeList.sort(compared)
                    break;   
                case 'course':
                    function comparee(a,b){
                        if (a.productName < b.productName)
                            return sort1
                        if(a.productName > b.productName)
                            return sort2
                        return 0
                    }
                    return this.timeList.sort(comparee)
                    break;
                case 'hourscourse':
                    function comparef(a,b){
                        if (a.totalHours_Course < b.totalHours_Course)
                            return sort1
                        if(a.totalHours_Course > b.totalHours_Course)
                            return sort2
                        return 0
                    }
                    return this.timeList.sort(comparef)
                    break;
                case 'hoursbtw':
                    function compareg(a,b){
                        if (a.totalHours_BTW < b.totalHours_BTW)
                            return sort1
                        if(a.totalHours_BTW > b.totalHours_BTW)
                            return sort2
                        return 0
                    }
                    return this.timeList.sort(compareg)
                    break;

                default:
                    return this.timeList
                    break;
            } 
            

            
        },
       
        lookuptogglelabel(){
            if(this.showLookup){return "Hide Lookup"}
            else{return "Show Lookup"}
        },


     
          ...mapFields([
              
              'loggedInUser.isAdmin',
              'pleaseWait',
              'pleaseWaitText',
             'imageBasePath',
             'locationList',
             'loggedInUser',
             'filter',
             'courseList',
             'studentList',
             'instructorList',
             'studentGroupList',
             'timeList',
             'timeTotals',
             'online',
             'procedureList',
             'tprFilter',
             'tprList',
             'tprList_All'

          ]),
    
        totalsString(){
            if(this.timeTotals!=undefined){
                var s = 'Students: ' + this.timeTotals.Employees + ' | Approvals: ' + this.timeTotals.Approvals  + ' of ' + this.timeTotals.Items + ' | Total Course Hours: ' 
                if(this.timeTotals.Hours_Course!=null){
                    s = s + this.timeTotals.Hours_Course.toFixed(2)
                }
                if(this.timeTotals.Hours_BTW!=null){
                    s = s + ' | Total BTW Hours: ' + this.timeTotals.Hours_BTW.toFixed(2)
                }
                return s
            }
            
        }
        
      },
      props:{
          
          
          
      },
      methods:{
        setsort(s){
            if(s==this.sortBy){this.sortaccending=!this.sortaccending}
            else{
                this.sortBy=s
            }
        },
        gotpr(){
            this.$emit('gotpr')
        },
        resetfilter(){

            this.filter.StartDateTime=this.$options.filters.formatDateForInputToday()
            this.filter.EndDateTime=this.$options.filters.formatDateForInputToday()
            this.filter.StudentID=-1
            this.filter.LocationID=this.loggedInUser.LocationId
            this.filter.includeInactive=false
            this.filter.studentGroupId=-1
            this.filter.studentStartDate= null

        },
        togglelookup(){
          //  console.log('toggle')
            this.showLookup=!this.showLookup
            localStorage.showLookup = this.showLookup
        },
    
        recordClass(g){
            var myclass 
            myclass="timerecord"
            if(g.timeStatus=='Removed'){
                myclass=myclass + ' timerecordremoved'
            }else if(g.timeStatus=='Issue'){
                myclass=myclass + ' timerecordissue'
            }else if(g.timeStatus=='Approved'){
                myclass=myclass + ' timerecordapproved'
            }else{
                myclass=myclass + ' timerecordregular'
            }
            return myclass
        },
        
        GradeButtonLabel(grade){
            if(grade.IsLocked){return "View Completed"}
            if(this.isBatchGrade(grade)){return "Grade GROUP"}
            else{return "Enter Grades"}
        },
        isBatchGrade(grade){
            return grade.StudentName.includes('GROUP ')
        },
        gradebuttonclass(grade){
            if(grade.isFailure){
                return "buttonred recordactionbutton"
            }
            else if(!grade.IsLocked){
                return "buttongreen recordactionbutton"
            }else{return "buttonprimary recordactionbutton"}
        },
        
        
        getRemoveString(name){
            return "Do you want to remove the time items for " + name + "?"
        },
        
       
        
        formatDate(dt){
            return this.$options.filters.formatDate(dt)
        },
        formatTime_late(dt,dt2=''){
            console.log('late')
            var ti = this.$options.filters.formatTime(dt)
            if(ti=='00:00 AM'){ti=''}

            if(dt2==''){return ti}

            var ti2 = this.$options.filters.formatTime(dt2)
            if(ti2=='00:00 AM'){ti2=''}

            console.log('formattime ' + ti + ' '  + ti2)
            if(ti2!='' && ti2>ti){return ti2}
            return ti
        },
        formatTime_early(dt,dt2=''){
            console.log('early1: ' + dt + ' 2:' + dt2)
            var ti = this.$options.filters.formatTime(dt)
            if(ti=='00:00 AM'){ti=''}

            if(dt2==''){return ti}

            var ti2 = this.$options.filters.formatTime(dt2)
            if(ti2=='00:00 AM'){ti2=''}

            console.log('formattimeearly' + ti + ' '  + ti2)
            if(ti2!='' && ti2<ti){return ti2}
            return ti
        },
        formatHours(hrs,hrs2){
            if(hrs2==null || hrs2==0){
                return hrs.toFixed(2)
            }else{
                return hrs.toFixed(2) + "/" + hrs2.toFixed(2)
            }
            
        },
        formatProductName(g){
            var name = g.productName
            if(g.productName2!=null){name = name + '/' + g.productName2}
            return name
        },
        async filtertime(showunsub,firsttime,afterclockin){
            if(!this.online){ return}
           // console.log('getting fresh grades')
            let req = {
                ProcedureFilter:this.filter
                
            }
             this.$store.dispatch('beforeAPI');
            var response = await this.callAPI(req,'timeitems_eval')
            //handle response
            if(response.Successful){
                this.sortBy='default'
                 this.timeList=response.TimeItemList
                 this.timeTotals = response.TimeTotals
                 this.pleaseWait=false;
                 
                
                 
                
            }else{
                this.responseMessage = response.Message
            }
            
        },
        /* async filtertime(showunsub,firsttime,afterclockin){
            if(!this.online){ return}
           // console.log('getting fresh grades')
            let req = {
                timeFilter:this.timeFilter
                
            }
             this.$store.dispatch('beforeAPI');
            var response = await this.callAPI(req,'timeitems')
            //handle response
            if(response.Successful){
                this.sortBy='default'
                 this.timeList=response.TimeItemList
                 this.timeTotals = response.TimeTotals
                 this.pleaseWait=false;
                 
                
                 
                
            }else{
                this.responseMessage = response.Message
            }
            
        }, */
     
        async getGroupList(){
            this.$store.dispatch('beforeAPI');
            var response = await this.callAPI(null,'studentgroups')
            //handle response
            if(response.Successful){
                this.studentGroupList=response.ValueList
                var all = {ID:-1, Name:'--All Student Groups--'}
                this.studentGroupList.unshift(all)
                this.filterBySponsor=-1
            }else{
                this.responseMessage = response.Message
            }
            this.pleaseWait=false; 
            
           
        },
         
          async getLocations(firsttime){
            if(!this.online){this.handleOffline('getloc');return}
            let req = {
                id:this.loggedInUser.user_personId
            }
             this.$store.dispatch('beforeAPI');
            var response = await this.callAPI(req,'locations')
            //handle response
            if(response.Successful){
                 this.locationList=response.GradeLocations
                 this.pleaseWait=false; 
                 //var all = {id:-1,name: '--All Locations--'}
                 //this.locationListWithAll.unshift(all)
                 if(this.loggedInUser.LocationId>0 ){
                    this.filter.LocationID=this.loggedInUser.LocationId
                    this.selectLocation(firsttime);
                 }else{
                    this.filter.LocationID=-1
                    this.selectLocation(firsttime);
                 }
                
            }else{
                this.responseMessage = response.Message
            }
            this.pleaseWait=false; 
          },
          async getProcedures(){
            if(!this.online){this.handleOffline('getprocedures');return}
            let req = {
                id:-1
            }
             this.$store.dispatch('beforeAPI');
            var response = await this.callAPI(req,'procedures')
            //handle response
            if(response.Successful){
                 this.procedureList=response.ProcedureList
                 var all = {id:-1, name:'--All Training--'}
                 this.procedureList.unshift(all)
                this.filter.procedureSequenceId=-1
            }else{
                this.responseMessage = response.Message
            }
            this.pleaseWait=false; 
          },
          async getStudents(){
            if(!this.online){this.handleOffline('getstu');return}
            let req = {
                LocationID:this.filter.LocationID,
                includeInactive:false
            }
             this.$store.dispatch('beforeAPI');
            var response = await this.callAPI(req,'students')
            //handle response
            if(response.Successful){
                 this.studentList=response.PersonList
                 var all = {id:-1, name:'--All Students--'}
                 this.studentList.unshift(all)
                this.filter.StudentID=-1
            }else{
                this.responseMessage = response.Message
            }
            this.pleaseWait=false; 
          },
          async getInstructors(){
            if(!this.online){this.handleOffline('getstu');return}
            let req = {
                LocationID:this.filter.LocationID
            }
             this.$store.dispatch('beforeAPI');
            var response = await this.callAPI(req,'instructors')
            //handle response
            if(response.Successful){
                 this.instructorList=response.PersonList
                 var all = {id:-1, name:'--All instructors--'}
                 this.instructorList.unshift(all)
                this.filter.InstructorID=this.loggedInUser.user_personId
            }else{
                this.responseMessage = response.Message
            }
            this.pleaseWait=false; 
          },
          async getCourses(){
            if(!this.online){this.handleOffline('getcourse');return}
            let req = {
                id:this.filter.LocationID
            }
             this.$store.dispatch('beforeAPI');
            var response = await this.callAPI(req,'timecourses')
            //handle response
            if(response.Successful){
                 this.courseList=response.TimeEventList
                 var all = {id:-1, name:'--All Courses--'}
                 this.courseList.unshift(all)
                this.courseId=-1
            }else{
                this.responseMessage = response.Message
            }
            this.pleaseWait=false; 
          },
          
          selectLocation(firsttime){
           // console.log('loc')
            this.getStudents();
            this.getInstructors();
            this.getProcedures();
           this.getGroupList()
            
            this.filtertime(true,firsttime);
           
            
          },
          //update
          okToUpdate(inst){
                return inst.timeStatus != "Removed" && inst.timeStatus != "Approved"
            },
          //removal
          okToRemove(inst){
                if(!this.showRemove){return false}
                return inst.timeStatus != "Removed" && inst.timeStatus != "Approved"
            },

           //APPROVALS
            setApprove(id){
                
                this.timeList.forEach(myFunction2)
                    function myFunction2(item, index, arr) {
                    let inst=arr[index]
                    if(inst.ID ==id){   
                        inst.toApprove=!inst.toApprove
                        console.log('set approve ' + inst.ID + ' ' + inst.toApprove)
                    } 
                }
                
                
            },  
            okToApprove(inst){
                return inst.timeStatus != "Removed" && inst.timeStatus != "Approved" && inst.timeStatus != "Issue" 
            },
            async saveApprovals(){
                if(!this.online){ return}
            
                let req = {
                    ProcedureInstanceList:this.timeList,
                    employeeId:this.loggedInUser.user_personId
                    
                }
                this.$store.dispatch('beforeAPI');
                var response = await this.callAPI(req,'approvetimeitems')
                //handle response
                if(response.Successful){
                    this.showApprove=false
                    this.filtertime()
                    this.pleaseWait=false;
                }else{
                    this.responseMessage = response.Message
                }
            },
            async remove(inst){
                if(!this.online){ return}
            
                let req = {
                    procedureInstance:inst
                    
                }
                this.$store.dispatch('beforeAPI');
                var response = await this.callAPI(req,'removetimeitem')
                //handle response
                if(response.Successful){
                    this.showApprove=false
                    this.filtertime()
                    this.pleaseWait=false;
                }else{
                    this.responseMessage = response.Message
                }
            },
            async filterTPR(showunsub,firsttime,afterclockin){
            this.tprFilter.date1=this.$options.filters.formatDateForInputToday()
            this.tprFilter.date2=this.$options.filters.formatDateForInputToday()
            this.tprFilter.studentId=-1
            this.tprFilter.locationId=this.loggedInUser.LocationId
            this.tprFilter.includeInactive=false
            this.tprFilter.studentStartDate=null

            // console.log('getting fresh grades')
            let req = {
                tprFilter:this.tprFilter
    
            }
            this.$store.dispatch('beforeAPI');
            var response = await this.callAPI(req,'tpritems')
            //handle response
            if(response.Successful){
            
                this.tprList=response.TPRItemList
                this.tprList_All=response.TPRItemList_All
                this.pleaseWait=false;

            }else{
                this.responseMessage = response.Message
            }
            
        },
        },  
        
      mounted(){
        
        this.currentDate = this.$options.filters.formatDateForInputToday()

        var tmp2 = localStorage.showLookup
        //console.log('show ' + tmp2)
        if(tmp2!=undefined){
            this.showLookup=tmp2=="true"
        }

        if(this.filter.LocationID==-1){
            this.resetfilter()
            
        }
        
        if(this.locationList.length==0){
            this.getLocations(true);
        }
        if(this.filter.InstructorID!=this.loggedInUser.user_personId){
            console.log('reset filter dif inst ' + this.filter.InstructorID + ' ' + this.loggedInUser.user_personId)
            this.getLocations(true);
            this.resetfilter()
            this.filtertime()
        }
        
        //initial tpr
    
        this.filterTPR()
      }
      
  }
  </script>
  
  <style>
  .homeDiv{
    display:grid;
    grid-template-rows: auto auto auto;
  }
  .buttons{
    display:flex;
    width:100%;
    flex-direction: row;
    justify-content: space-between;
  }
  .filter{
    padding-left:10px;
  }
  .timerecord{
    text-align:left;
    
    margin:5px;
    border-radius: 2px;
    padding:0px 0px 0px 5px;
    display:grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 2fr 1fr 1fr 1fr 2fr;
  }
  .timerecordregular{
    background-color:var(--frame-color);
  }
  .timerecordapproved{
    background-color:rgb(209, 224, 209);
  }
  .timerecordissue{
    background-color:rgb(240, 204, 158);
  }
  .timerecordremoved{
    background-color:rgb(243, 191, 191);
  }
  .timeheader{
    text-align:left;
 
    margin:5px;
    border-radius: 2px;
    padding:0px 0px 0px 5px;
    display:grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 2fr 1fr 1fr 1fr 2fr;
  }
.recordlabel{
    text-align: left;
} 

.recordactionbutton{

border: none;
border-radius:5px;
width:120px;
height:30px;
padding:0px;
font-size: medium;
margin:5px;
text-decoration: none;

}
.filterfield{
    height:30px;
    margin:5px;
    max-width:110px;
    font-size:inherit;
}
.filterselect{
    height:35px;
    margin:5px; 
    min-width: 125px;
    font-size:inherit;
}
.filterlabel{
    font-size:larger;
    margin:10px 0px 0px 0px;
    color:var(--text-color);
}
.removeme{
    margin-top:10px;
    margin-left:5px;
}
.redborder{
    border:2px red solid;
}
.approveCheck{
    margin-top:20px;
    margin-left:20px;
}
.vertScroll{
    overflow-x:hidden;
    overflow-y:scroll;
    width:100%;
    height:480px;
  }
  .recordheader{
    margin:0px 0px 0px 0px;
}
  </style>

